import React from 'react';
import { PrismicRichText } from '@prismicio/react';
import {
  Accordion,
  AccordionItem,
  AccordionTitle,
  AccordionBody,
  IconWrapper,
  CloseIcon,
  OpenIcon,
} from 'common/components/Accordion';
import { Mixpanel } from 'utils/mixpanel';
import {
  FaqQuestion,
  FaqWrapper,
  FaqAnswer,
  ContentSVG,
  ParentFaq,
  ChildFaq,
  FaqAside,
  MarginDescription,
  HeaderFaq,
} from './faq.style';
import Container from 'common/components/Container';
import TypographyTitle from 'common/components/Typography/Title';
import TypographyDescription from 'common/components/Typography/Description';
import BtnGetStarted from 'common/components/Button/BTNGetStarted';
import NextImage from 'common/components/NextImage';
import Close from 'common/assets/image/logo/icon/Close';
import Open from 'common/assets/image/logo/icon/Open';

const FaqSection = ({ slice }) => {
  const onCTAClick = () => {
    Mixpanel.track('Landing Page - FAQ Section CTA Click', {
      CTA: slice.primary.cta_text,
    });
  };

  return (
    <>
      <Container>
        <ParentFaq>
          <ChildFaq>
            <HeaderFaq>
              <TypographyTitle>
                {slice.primary.title}
              </TypographyTitle>
              <MarginDescription>
                <TypographyDescription>
                  {slice.primary.subtitle}
                </TypographyDescription>
              </MarginDescription>
              <BtnGetStarted label={slice.primary.cta_text} />
            </HeaderFaq>
            <FaqAside>
              <NextImage field={slice.primary.banner} fill={true} />
            </FaqAside>
          </ChildFaq>
          <ChildFaq>
            <Accordion>
              <FaqWrapper>
                {slice.items.map((accordionItem, index) => (
                  <AccordionItem
                    className='accordion_item'
                    key={`accordion-${index}`}
                    expanded={accordionItem.expend}
                  >
                    <>
                      <AccordionTitle className='accordion_title'>
                        <>
                          <FaqQuestion>
                            {accordionItem.question_title}
                          </FaqQuestion>
                          <IconWrapper>
                            <OpenIcon>
                              <Open />
                            </OpenIcon>
                            <CloseIcon>
                              <Close />
                            </CloseIcon>
                          </IconWrapper>
                        </>
                      </AccordionTitle>
                      <AccordionBody className='accordion_body'>
                        <FaqAnswer>
                          <PrismicRichText
                            field={accordionItem.question_answer}
                          />
                        </FaqAnswer>
                      </AccordionBody>
                    </>
                  </AccordionItem>
                ))}
              </FaqWrapper>
            </Accordion>
          </ChildFaq>
        </ParentFaq>
      </Container>
    </>
  );
};

export default FaqSection;
